import axios from "axios";
import { User } from "firebase/auth";
import React, { useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import { matchPath } from "react-router-dom";
import { Loading } from "../components/Loading";
import { isProd } from "../constants";
import { IfxPrivilegeName } from "../constants/types";
import {
  TOKEN_EMBED_URL,
  LOGIN_URL,
  CREDENTIALS_EMBED_URL,
  TOKEN_INTERACT_URL,
  CREDENTIALS_INTERACT_URL,
} from "../constants/urls";
import { ifxApiClient, unauthorizedClient } from "../helpers/api";
import {
  CheckAuthorizeResponse,
  IfxPostAuthorizeData,
  IfxPostAuthorizeResponse,
} from "../helpers/ifxTypes";
import { NonNullableValues } from "../helpers/types";
import { useFirebase } from "./firebase";

async function checkAuthorization(idToken: string) {
  try {
    const response = await unauthorizedClient.post<IfxPostAuthorizeResponse, IfxPostAuthorizeData>(
      "/authorize",
      { idToken }
    );

    return response.data;
  } catch (error) {
    if (!isProd) {
      console.error("Error from auth api: " + error);
    }

    throw error;
  }
}

async function refreshTokenForUser(user: User, forceRefresh = false) {
  try {
    const token = await user.getIdToken(forceRefresh);
    return token;
  } catch (err) {
    console.error("REFRESH ERROR", err);
    throw err;
  }
}

export type EnhancedUser = User & {
  mainOrgUnitPrivileges: CheckAuthorizeResponse["r"];
  privileges: CheckAuthorizeResponse["orgUnitPrivilegeMap"][string][number]["privilegeName"][];
  privilegeMap: CheckAuthorizeResponse["orgUnitPrivilegeMap"];
  hrbpOrgunitIds: string[];
};

export type AuthCustomer = CheckAuthorizeResponse["c"];

export type AuthContextShape = {
  authUser: null | EnhancedUser;
  customer: null | AuthCustomer;
  idToken: null | string;
  loading: boolean;

  signInWithEmailAndPassword: (email: string, password: string) => Promise<void>;
};

const initialState: AuthContextShape = {
  authUser: null,
  customer: null,
  idToken: null,
  loading: false,

  signInWithEmailAndPassword: async () => {
    /* noop */
  },
};

function authenticating() {
  return { type: "AUTHENTICATING", payload: {} } as const;
}

function authTokenUpdated(newToken: string) {
  return { type: "AUTH_TOKEN_UPDATED", payload: { newToken } } as const;
}

function userAuthenticated(
  data: Required<
    Pick<AuthContextShape, "customer" | "idToken"> & {
      authUser: User;
      mainOrgUnitPrivileges: CheckAuthorizeResponse["r"];
      privilegeMap: CheckAuthorizeResponse["orgUnitPrivilegeMap"];
      hrbpOrgunitIds: string[];
    }
  >
) {
  return {
    type: "AUTHENTICATED",
    payload: data,
  } as const;
}

function logOut() {
  return {
    type: "LOG_OUT",
    payload: { ...initialState },
  } as const;
}

function resetState() {
  return {
    type: "RESET",
    payload: { ...initialState },
  } as const;
}

type AuthContextAction =
  | ReturnType<typeof authenticating>
  | ReturnType<typeof authTokenUpdated>
  | ReturnType<typeof logOut>
  | ReturnType<typeof resetState>
  | ReturnType<typeof userAuthenticated>;

const reducer = (state: AuthContextShape, action: AuthContextAction): AuthContextShape => {
  console.log("AuthContext reducer", action.type, action.payload);
  switch (action.type) {
    case "AUTHENTICATING":
      return {
        ...state,
        loading: true,
      };
    case "AUTH_TOKEN_UPDATED":
      return {
        ...state,
        idToken: action.payload.newToken,
      };
    case "AUTHENTICATED": {
      const {
        authUser,
        customer,
        idToken,
        mainOrgUnitPrivileges,
        hrbpOrgunitIds,
        privilegeMap = {},
      } = action.payload;

      // flatten orgUnitPrivilegeMap down to an array of given privileges for this user
      const privilegesSet = new Set(
        Object.values(privilegeMap).flatMap(orgUnitPrivileges =>
          orgUnitPrivileges.map(priv => priv.privilegeName)
        )
      );
      if (hrbpOrgunitIds?.length) {
        privilegesSet.add("HRBP" as IfxPrivilegeName);
      }
      const privileges = Array.from(privilegesSet);
console.log("AUTHENTICATED", authUser, customer);
      return {
        ...state,
        authUser: Object.assign(authUser, {
          mainOrgUnitPrivileges,
          privileges,
          privilegeMap,
          hrbpOrgunitIds,
        }),
        customer,
        idToken,
        loading: false,
      };
    }
    case "LOG_OUT":
      return { ...state, ...action.payload, loading: false };
    case "RESET":
      return { ...state, ...action.payload, loading: false };
    default:
      return state;
  }
};

const AuthContext = React.createContext<AuthContextShape>(initialState);

export const useAuth = () => useContext(AuthContext);

export const useAssertAuthUser = () => {
  const authUserContext = useContext(AuthContext);

  if (!authUserContext.authUser) {
    throw new Error("You must be logged in.");
  }

  return authUserContext as NonNullableValues<AuthContextShape>;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const firebase = useFirebase();
  const [state, dispatch] = useReducer(reducer, initialState);
console.log("AuthProvider");
  useEffect(() => {
    // The embed screen authenticates itself
    const tokenEmbedMatch = matchPath(window.location.pathname, {
      path: [TOKEN_EMBED_URL, TOKEN_INTERACT_URL],
      exact: true,
      strict: false,
    });
    const credentialsEmbedMatch = matchPath(window.location.pathname, {
      path: [CREDENTIALS_EMBED_URL, CREDENTIALS_INTERACT_URL],
      exact: true,
      strict: false,
    });
    const embedMatch = tokenEmbedMatch || credentialsEmbedMatch;

    // on auth state changed
    const unsubscribe = firebase.auth.onIdTokenChanged(async authUser => {
      try {
        if (!authUser) {
          throw new Error("User is logged out.");
        }

        dispatch(authenticating());
        const idToken = await refreshTokenForUser(authUser, true);
        const {
          r: mainOrgUnitPrivileges,
          orgUnitPrivilegeMap: privilegeMap,
          c: customer,
          hrbpOrgunitIds,
        } = await checkAuthorization(idToken);

        dispatch(
          userAuthenticated({
            authUser,
            customer,
            idToken,
            mainOrgUnitPrivileges,
            privilegeMap,
            hrbpOrgunitIds,
          })
        );
      } catch (err) {
        console.warn("authUser withAuth error: ", err);
        dispatch(resetState());

        if (window.location.pathname !== LOGIN_URL && !embedMatch) {
          window.location.assign(LOGIN_URL);
        }
      }
    });

    return () => {
      unsubscribe();
    };
    // Only run on mount/unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const requestInterceptorId = ifxApiClient.interceptors.request.use(
      async config => {
        const token = state.authUser ? await refreshTokenForUser(state.authUser) : null;

        if (token) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };
        }

        return config;
      },
      err => {
        console.error("Interceptor error", err);
        if (
          axios.isAxiosError(err) &&
          err.response?.status &&
          [401, 403].includes(err.response.status)
        ) {
          console.info(`${err.response.status} from API Intercepted, reloading user`);
          window.location.reload();
          return;
        }

        return Promise.reject(err);
      }
    );

    return () => {
      ifxApiClient.interceptors.request.eject(requestInterceptorId);
    };
  }, [state.authUser]);

  const signInWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      try {
        console.log("Signing in with email(", email, ")and password");
        const userCredentials = await firebase.doSignInWithEmailAndPassword(email, password);
        const idToken = await userCredentials.user.getIdToken();
        const {
          r: mainOrgUnitPrivileges,
          orgUnitPrivilegeMap: privilegeMap,
          c: customer,
          hrbpOrgunitIds,
        } = await checkAuthorization(idToken);

        dispatch(
          userAuthenticated({
            authUser: userCredentials.user,
            customer,
            idToken,
            mainOrgUnitPrivileges,
            privilegeMap,
            hrbpOrgunitIds,
          })
        );
      } catch (err) {
        throw err;
      }
    },
    [firebase]
  );

  const contextValue = useMemo(
    () => ({
      ...state,
      signInWithEmailAndPassword,
    }),
    [signInWithEmailAndPassword, state]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {state.loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
}

export const withAuth =
  <P extends Record<string, unknown>>(Component: React.ComponentType<P>) =>
  (props: P & { auth: AuthContextShape }) =>
    <AuthContext.Consumer>{auth => <Component {...props} auth={auth} />}</AuthContext.Consumer>;

export default AuthContext;

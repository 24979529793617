// This first section is the new code using RecordRTC to record audio and video.
import React, { useEffect, useState } from 'react';
import { Button, Progress } from "reactstrap";
import RecordRTC from 'recordrtc';
import { v4 as uuidv4 } from "uuid";
import { useFirebase } from "../../../contexts/firebase";
import VideoPreview from './VideoPreview';

interface RecordScreenProps {
  onAnalyze: (audioUrl: string) => void;
}
 
const RecordScreen = ({ onAnalyze }: RecordScreenProps) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioRecorder, setAudioRecorder] = useState<RecordRTC | null>();
  const [videoRecorder, setVideoRecorder] = useState<RecordRTC | null>();
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [recordedVideo, setRecordedVideo] = useState<{ blob: Blob; url: string } | null>(null);

  const [audioUrl, setAudioUrl] = useState<string | null>(null);  
  // the name of the page has changed from 'interact' to 'embed' but this stayed the same to keep the existing file structure
  const [firebaseUrl] = useState<string>(() => `interact/_${uuidv4()}.mp4`); 
  console.log("IFX: on entry to RecordScreen, audio URL is: ", audioUrl, " and firebaseUrl is",firebaseUrl);
  const firebase = useFirebase();

  const getStream = async () => {
    try {
      if ('mediaDevices' in navigator === false) {
        alert('Sorry, your browser does not support media devices.');
        return;
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      if (stream.getVideoTracks().length === 0) {
        alert('Video track not available. Trying with audio only.');
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setStream(audioStream);
      } else {
        setStream(stream);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStream();
  }, []);

  const startRecording = () => {
    try {
      if (!stream) {
        alert('No media stream available');
        return;
      }
      setRecordedVideo(null);
      const audioRecordRTC = new RecordRTC(stream, { type: 'audio' });
      const videoRecordRTC = new RecordRTC(stream, {
        type: 'video',
        recorderType: RecordRTC.MediaStreamRecorder,
        mimeType: 'video/webm;codecs=vp8',
      });
      setAudioRecorder(audioRecordRTC);
      setVideoRecorder(videoRecordRTC);
      audioRecordRTC.startRecording();
      videoRecordRTC.startRecording();
    } catch (error) {
      console.error(error);
    }
  };

  const stopRecording = () => {
    if (audioRecorder && videoRecorder) {
      console.info('IFX:  Stopping recording...');
      audioRecorder.stopRecording(() => {
        const audioBlob = audioRecorder.getBlob();
        setAudioBlob(audioBlob);
        setAudioRecorder(null); // Set audio recorder to null after stopping
      });
      videoRecorder.stopRecording(() => {
        const recordedVideoBlob = videoRecorder.getBlob();
        const recordedAudioURL = URL.createObjectURL(recordedVideoBlob);
        setRecordedVideo({ blob: recordedVideoBlob, url: recordedAudioURL });
        setVideoRecorder(null); // Set video recorder to null after stopping
      });
    }
  };

  useEffect(() => {
    return () => {
      if (audioRecorder) {
        audioRecorder.destroy(); // Destroy audio recorder instance when component unmounts
      }
      if (videoRecorder) {
        videoRecorder.destroy(); // Destroy video recorder instance when component unmounts
      }
    };
  }, []);

  const handleSubmit = async () => {
    console.info("****************************");
    console.info("IFX: RecordScreen handleSubmit called to upload audioBlob to firebase storage");
    console.info("     audioUrl: ", audioUrl, " and firebaseUrl is", firebaseUrl);
    if (audioBlob && firebaseUrl) {
      var audioRef = firebase.storage.ref(firebaseUrl);
      try {
        setResult('');
        setLoading(true);
        const formData = new FormData();

        formData.append('audio', audioBlob);
        console.info("IFX:  audioRef before upload call: ", audioRef);
        // const uploadresults = firebase.storage.uploadBytes(audioRef, audioBlob).then(() => {
        //   firebase.storage.getDownloadURLForRef(audioRef).then(url => {
        //     setAudioUrl(url);
        //   });
        // });
        const uploadresults = await firebase.storage.uploadBytes(audioRef, audioBlob) 
        console.info("IFX:  upload results: ", uploadresults);
        firebase.storage.getDownloadURLForRef(audioRef).then(url => {
          setAudioUrl(url);
        });
        
        console.info("finished uploadBytes with audioUrl:", audioUrl, " and firebaseUrl:",firebaseUrl);

        // varyence upload code:
        // const { data } = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/upload`, formData);
        // setResult(data.text);
        setLoading(false);
      } catch (err: any) {
        console.error("IFX:  can't submit", err?.response?.data || err.message);
        alert(err?.response?.data || err.message);
        setLoading(false);
      }
    }
  };

  return (
    <div className="wrapper">

      {!recordedVideo && (
        <div className="video-wrap">
          <VideoPreview stream={stream} />
        </div>
      )}
      {recordedVideo && (
        <div className="audio">
          <video width={500} height={500} src={recordedVideo.url} controls />
        </div>
      )}
      <div className="actions">
        {!audioRecorder && (
          <Button           
            style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
            block
            color={"info"}
            onClick={startRecording} className="button">
            Start Recording
          </Button>
        )}
        {audioRecorder && (
          <Button 
          style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
          block
          color={"info"}
          onClick={stopRecording} className="button">
            Stop Recording...
          </Button>
        )}
        {audioBlob && (
          <Button 
          style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
          block
          color={"info"}
          onClick={async () => {
              await handleSubmit();
              onAnalyze(firebaseUrl);
          }} className="button" disabled={loading}>
            {loading ? 'Analyzing...' : 'Analyze'}
          </Button>
        )}
      </div>

    </div>
  );
};

export default RecordScreen;
